import useEnginePhaseSimulator from '../hooks/UseEnginePhaseSimulator';
import { Button, FormControl, Paper, Slider, Stack, TextField, Input, styled } from '@mui/material';
import Resizable from './Resizable';
import { useEffect, useRef, useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';
import { brotliDecompress } from 'zlib';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 0,
    minHeight: '1.0em',
    maxHeight: '1.0em',
}));

function WaveformOverlay() {
    const gaEventTracker = useAnalyticsEventTracker('WaveformOverlay');
    
    const divRef = useRef<HTMLDivElement>(null);

    const getPosition = () => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            return rect
        }
        return new DOMRect(100, 10, 100, 10)
    };

    const { stepEngine, CylinderStates, CylinderFiringSequence, setCylinderFiringSequence, setCurrentCrankshaftRotationAngle, engineCycles, valveEvents } = useEnginePhaseSimulator();

    const stepDisabled = ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(CylinderFiringSequence.length) === false);

    const setFiringOrder = (value: string) => {
        let firingOrder = Array<number>();
        for (let i = 0; i < value.length; i++) {
            firingOrder.push(Number.parseInt(value[i]));
        }
        setCylinderFiringSequence(firingOrder);
        setCurrentCrankshaftRotationAngle((value.length === 6) ? 30 : 0);
    }

    const [file, setFile] = useState("");
    
    function fileUploaded(e: any) {
        gaEventTracker('fileUploaded');
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handlePasteEvent = (e: ClipboardEvent) => {
        var items = e.clipboardData?.items;
        if(!items) {
            return;
        }

        for (var i = 0; i < items.length; i++) {
          if (items[i].kind === 'file' && items[i].type.startsWith('image/')) {
            var blob = items[i].getAsFile();
            if(blob) {
                var url = URL.createObjectURL(blob);
                setFile(url); //update the state with the new image url
                gaEventTracker('fileUploaded');
            }
            return;
          }
        }
      };

    const [opacity, setOpacity] = useState<number>(0.5);

    const opacityChanged = (event: Event, newValue: number | number[]) => {
        setOpacity(newValue as number);
    };

    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const [draggingOffset, setDraggingOffset] = useState<{x: number, y: number} | null>(null);

    function setupCircles() {
        const rect = getPosition();
        const left = (rect.width / 2) - (((CylinderFiringSequence.length + valveEvents.length - 3) / 2) * 50);
        
        const cylinders = CylinderFiringSequence.map((cyl, index) => ({
            name: cyl,
            x: left + (index * 50),
            y: rect.y,
        }));

        const valves = valveEvents.map((valve, index) => ({
            name: valve,
            x: left + ((index + cylinders.length) * 50),
            y: rect.y,
        }));

        const combined = [...cylinders, ...valves];
        return combined;
    }
    
    const [circlePositions, setCirclePositions] = useState(setupCircles());

    useEffect(() => {
        setCirclePositions(setupCircles());
    }, [CylinderFiringSequence]);

    const onDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setDraggingIndex(index);
        setDraggingOffset({ x: e.clientX - circlePositions[index].x, y: e.clientY - circlePositions[index].y });
    };

    const onDragMove = (e: MouseEvent) => {
        if (draggingIndex === null) return;
        const newPositions = [...circlePositions];
        newPositions[draggingIndex].x = e.clientX - (draggingOffset?.x || 0);
        newPositions[draggingIndex].y = e.clientY - (draggingOffset?.y || 0);

        setCirclePositions(newPositions);
    };

    const onDragEnd = () => {
        setDraggingIndex(null);
    };

    useEffect(() => {
        document.addEventListener('paste', handlePasteEvent);
        document.addEventListener('mousemove', onDragMove);
        document.addEventListener('mouseup', onDragEnd);
        return () => {
            document.removeEventListener('paste', handlePasteEvent);
            document.removeEventListener('mousemove', onDragMove);
            document.removeEventListener('mouseup', onDragEnd);
        };
    }, [draggingIndex, circlePositions]);
     
    return (
        <div className="App">
            <h3>Pressure Waveform Overlays</h3>
            <div className="w99" style={{ padding: '0px 0px 0px 0px' }}>
                <FormControl sx={{ m: 1, minWidth: 140, maxWidth: 140 }}>
                    <TextField id='firing-order'
                        type="number"
                        value={CylinderFiringSequence.join('')}
                        onChange={e => setFiringOrder(e.target.value)}
                        onKeyDown={e => ["Enter", "NumpadEnter"].includes(e.code) ? stepEngine() : null}
                        placeholder="Cylinder Firing Order"
                        label="Cylinder Firing Order"
                    />
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                    <Button
                        variant="outlined" color="primary" 
                        component="label"
                        >
                        <Input type="file" onChange={fileUploaded} />
                    </Button>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 140, maxWidth: 140 }}>
                    <Button disabled={stepDisabled} variant="outlined" color="primary" aria-label="Sync" size="large" onClick={stepEngine}>Sync</Button>
                </FormControl>
            </div>
            <div className="w99" style={{ padding: '0px 0px 0px 0px', maxHeight: '45px' }}>
                <FormControl sx={{ m: 1, minWidth: 400, maxWidth: 400 }}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <VisibilityOffOutlinedIcon />
                        <Slider aria-label="Opacity" value={opacity} onChange={opacityChanged} 
                            min={0.1}
                            max={0.99} step={0.025} />
                        <VisibilityOutlinedIcon />
                    </Stack>
                </FormControl>
            </div>
            <div className="w99" style={{ padding: '3px 0px 13px 0px', lineHeight: '1.3em' }}>
                <strong>1. </strong>Cylinder Firing Order &nbsp;|&nbsp; <strong>2. </strong>Paste/Upload Waveform &nbsp;|&nbsp; <strong>3. </strong>Move/Resize Overlay<br/>
                <strong>4. </strong>Sync Power Cylinder &nbsp;|&nbsp; <strong>5. </strong>Set Transparency &nbsp;|&nbsp; <strong>6. </strong>Drag Cylinder Markers<br/>
                {engineCycles.map((ec) => <span><span style={{width: '40px', height: '40px', borderRadius: '50%', backgroundColor: ec.colorRepresentation}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;<strong>{ec.name}</strong>&nbsp;&nbsp;&nbsp;</span>)}
            </div>
            <div ref={divRef} className="w99" style={{ padding: '0px 0px 0px 0px', minHeight: '45px' }}>
                {circlePositions.map((circle, index) => (
                    <div
                        key={circle.name}
                        style={{
                            position: 'absolute',
                            top: circle.y,
                            left: circle.x,
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: 'lightgray',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'grab',
                            fontWeight: 'bold',
                            color: 'black',
                            userSelect: 'none',
                            opacity: 0.97,
                            zIndex: 1001,
                        }}
                        onMouseDown={(e) => onDragStart(e, index)}
                    >
                        {circle.name}
                    </div>
                ))}
            </div>
            <div style={{backgroundImage: `url(${file})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', margin: '0 auto', minWidth:'70%', maxWidth:'90%', width: window.innerWidth * .9, minHeight: '300px', maxHeight: '70%', height: window.innerHeight * .7}}>
                &nbsp;
            </div>

            <Resizable
                height={CylinderStates.length * (Number.parseFloat(getComputedStyle(document.body).fontSize) * 2.34)}
                minWidth={50}
                fixedChildren={
                    <div>
                    {CylinderStates.map((cyl) => (
                        <div
                        key={cyl.Number}
                        style={{
                            height: `${Number.parseFloat(getComputedStyle(document.body).fontSize) * 2.34}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "0px",
                        }}
                        >
                        {cyl.Number}
                        </div>
                    ))}
                    </div>
                }
                resizableChildren={
                    <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${CylinderStates[0]?.CylinderPhases.length}, 1fr)`,
                        gap: "0px", // Optional spacing between columns
                        opacity: opacity, // Apply opacity here
                        transition: "opacity 0.3s ease", // Smooth opacity transitions
                    }}
                    >
                    {CylinderStates.map((cyl) =>
                        cyl.CylinderPhases.map((ph, index) => (
                        <div
                            key={`${cyl.Number}-${index}`}
                            style={{
                            backgroundColor: ph.colorRepresentation,
                            height: `${Number.parseFloat(getComputedStyle(document.body).fontSize) * 2.34}px`,
                            border: "0px",
                            opacity: 1, // Ensure each child is fully visible (opacity is applied at the parent level)
                            }}
                        />
                        ))
                    )}
                    </div>
                }
                />



        </div>
    );
}
export default WaveformOverlay;

