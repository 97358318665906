import React, { useState, useEffect } from 'react';

interface EngineCycle {
    code: string;
    name: string;
    colorRepresentation: string;
    startDegrees: number;
    finishDegrees: number;
}
  
interface CylinderState {
    Number: number;
    CylinderPhases: EngineCycle[];
}

const TotalDegreesPerTwoRotations = 720;
const initialCylinderFiringSequence = [1, 8, 7, 2, 6, 5, 4, 3];

const useEnginePhaseSimulator = () => {
    const [CylinderFiringSequence, setCylinderFiringSequence] = useState(initialCylinderFiringSequence);
    const [CurrentCrankshaftRotationAngle, setCurrentCrankshaftRotationAngle] = useState(0);
    const [CylinderStates, setCylinderStates] = useState(Array<CylinderState>());

    const CylinderCount = React.useMemo(() => {
        // console.log(CylinderFiringSequence.length);
        return CylinderFiringSequence.length;
    }, [CylinderFiringSequence]);

    const DegreesCoveredPerCycle = React.useMemo(() => {
        const degreesCoveredPerCycle = Math.ceil(TotalDegreesPerTwoRotations / CylinderCount);
        // console.log(degreesCoveredPerCycle);
        return degreesCoveredPerCycle;
    }, [CylinderCount]);

    const DegreesUntilNextCylinderPowerStroke = React.useMemo(() => {
        let degreesUntilNextCylinderPowerStroke = 90;

        if(CylinderCount == 1) {
            degreesUntilNextCylinderPowerStroke = 180;
        }
        else if(CylinderCount == 3) {
            degreesUntilNextCylinderPowerStroke = 60;
        }
        else if(CylinderCount == 7) {
            degreesUntilNextCylinderPowerStroke = 25;
        }
        else if(CylinderCount == 9) {
            degreesUntilNextCylinderPowerStroke = 20;
        }
        else if(DegreesCoveredPerCycle < 91) {
            degreesUntilNextCylinderPowerStroke = 90;
        }
        else {
            degreesUntilNextCylinderPowerStroke = DegreesCoveredPerCycle / 2;
        }
        // console.log(degreesUntilNextCylinderPowerStroke);

        return degreesUntilNextCylinderPowerStroke;
    }, [DegreesCoveredPerCycle]);

    const CycleOverlapFactor = React.useMemo(() => {
        return (DegreesCoveredPerCycle % 90 === 0) ? 1 : (180.0 / DegreesCoveredPerCycle);
    }, [DegreesCoveredPerCycle]);
    
    const ColumnsCount = React.useMemo(() => {
        if (CylinderCount === 1 || CylinderCount === 2) {
            return 4;
        } 
        else if (CylinderCount === 3) {
            return 12;
        } 
        else if (CylinderCount === 5) {
            return 20;
        } 
        else if (CylinderCount === 7) {
            return 28;
        } 
        else if (CylinderCount === 9) {
            return 36;
        } 
        else if (CylinderCount === 10) {
            return 40;
        }
        else {
            // Original logic for other engines
            const cycleOverlap = CylinderCount * CycleOverlapFactor;
            const initialColumnCount = Math.max(8, Math.floor(cycleOverlap));
            const columnCount = initialColumnCount % 4 === 0 
                                ? initialColumnCount 
                                : initialColumnCount + 4 - (initialColumnCount % 4);
            return columnCount;
        }
    }, [CylinderCount, CycleOverlapFactor]);


    const engineCycles: EngineCycle[] = [
        { code: 'P', name: 'Power', colorRepresentation: 'Firebrick', startDegrees: 0, finishDegrees: 179 },
        { code: 'E', name: 'Exhaust', colorRepresentation: 'Black', startDegrees: 180, finishDegrees: 359 },
        { code: 'I', name: 'Intake', colorRepresentation: 'Yellow', startDegrees: 360, finishDegrees: 539 },
        { code: 'C', name: 'Compression', colorRepresentation: 'Orange', startDegrees: 540, finishDegrees: 719 },
    ];

    const valveEvents: string[] = [
        "EVO", "IVO", "EVC", "IVC"
    ];

    useEffect(() => {
        stepEngine();
    }, [CylinderCount]);

    const stepEngine = () => {
        const newCylinderStates: CylinderState[] = [];
        const myCurrentCrankshaftRotationAngle = CurrentCrankshaftRotationAngle + DegreesCoveredPerCycle;
        setCurrentCrankshaftRotationAngle(myCurrentCrankshaftRotationAngle);
        for (let currentCylinderIndex = 0; currentCylinderIndex<CylinderCount; currentCylinderIndex++) {
            let cylinderState = {Number : CylinderFiringSequence[currentCylinderIndex], CylinderPhases: Array<EngineCycle>()};
            newCylinderStates.push(cylinderState);
            for (let currentStepIndex = 0; currentStepIndex < ColumnsCount; currentStepIndex++) {
                let currentPhaseAngle = getCurrentPhaseAngle(currentStepIndex, currentCylinderIndex, myCurrentCrankshaftRotationAngle);
                let currentCyclePhase = getCurrentCyclePhase(currentPhaseAngle);
                if(currentCyclePhase) {
                    cylinderState.CylinderPhases.push(currentCyclePhase);
                }
            }
        }
        // console.log(newCylinderStates[0].CylinderPhases.length);
        setCylinderStates(newCylinderStates);
    };

    const getCurrentCyclePhase = (currentPhaseAngle: number) => {
        const cycle = engineCycles.find((c: { startDegrees: number; finishDegrees: number; }) => currentPhaseAngle >= c.startDegrees && currentPhaseAngle <= c.finishDegrees);
        return (cycle ?? { code: "", name:"", colorRepresentation: 'White', startDegrees: 0, finishDegrees: 0})
    }

    const getCurrentPhaseAngle = (currentStepIndex: number, currentCylinderIndex: number, currentCrankshaftRotationAngle: number) => {
        let offset = currentStepIndex * DegreesUntilNextCylinderPowerStroke;
        // if cylinder count equals 5 we need to step 36 degrees to create 5 cells for the grid
        if(CylinderCount === 5) { offset = offset / 2; }
        if(CylinderCount === 10) { offset = offset / 5; }
        const trueAngle = normalizeAngle(currentCrankshaftRotationAngle + offset);
        const phaseOffset = DegreesCoveredPerCycle * (currentCylinderIndex + 1);
        const currentPhaseAngle = normalizeAngle(trueAngle - phaseOffset);
        
        return currentPhaseAngle;
    };
    
    const normalizeAngle = (degrees: number) => {
        degrees = degrees % (TotalDegreesPerTwoRotations);
        if (degrees < 0){
            degrees += (TotalDegreesPerTwoRotations);
        }
        return degrees;
    };
    
    return {
        CylinderStates,
        CylinderFiringSequence,
        engineCycles,
        setCylinderFiringSequence,   
        setCurrentCrankshaftRotationAngle,
        stepEngine,
        valveEvents
    };
};

export default useEnginePhaseSimulator;