import React, { ReactNode, useState } from "react";
import OpenWithOutlinedIcon from "@mui/icons-material/OpenWithOutlined";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

type Size = { width: number; height: number };
type Position = { x: number; y: number };

type ResizableProps = {
  height: number;
  minWidth: number;
  fixedChildren: ReactNode; // Fixed section (e.g., cylinder numbers)
  resizableChildren: ReactNode; // Resizable section (e.g., cylinder phases grid)
};

const Resizable: React.FC<ResizableProps> = (props: ResizableProps) => {
  const [size, setSize] = useState<Size>({
    width: window.innerWidth / 2,
    height: props.height,
  });
  const [position, setPosition] = useState<Position>({
    x: window.innerWidth / 2 - size.width / 2,
    y: window.innerHeight / 2 - 80,
  });

  let moveOffset: Position = { x: 0.0, y: 0.0 };
  let resizeOffset: Position = { x: 0.0, y: 0.0 };

  const disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = "auto";
  };

  const onResizeMouse = (e: React.MouseEvent) => {
    e.preventDefault();
    resizeOffset = {
      x: (e.clientX - (position.x + size.width)) * -1,
      y: 0.0,
    };
    document.addEventListener(
      "mousemove",
      onResizeMouseMove as EventListener
    );
    document.addEventListener("mouseup", onResizeMouseUp as EventListener);
  };

  const onResizeMouseMove = (e: globalThis.MouseEvent) => {
    e.preventDefault();
    const newWidth = e.clientX - position.x + resizeOffset.x;
    setSize({
      width: Math.max(newWidth, props.minWidth),
      height: props.height,
    });
  };

  const onResizeMouseUp = () => {
    document.removeEventListener(
      "mousemove",
      onResizeMouseMove as EventListener
    );
    document.removeEventListener("mouseup", onResizeMouseUp as EventListener);
  };

  const onMoveMouse = (e: React.MouseEvent) => {
    e.preventDefault();
    moveOffset = { x: e.clientX - position.x, y: e.clientY - position.y };

    document.addEventListener("mousemove", onMoveMouseMove as EventListener);
    document.addEventListener("mouseup", onMoveMouseUp as EventListener);
  };

  const onMoveMouseMove = (e: globalThis.MouseEvent) => {
    e.preventDefault();
    setPosition({
      x: e.clientX - moveOffset.x,
      y: e.clientY - moveOffset.y,
    });
  };

  const onMoveMouseUp = () => {
    document.removeEventListener("mousemove", onMoveMouseMove as EventListener);
    document.removeEventListener("mouseup", onMoveMouseUp as EventListener);
  };

  return (
    <div
      style={{
        display: "flex", // Align fixed and resizable sections side by side
        position: "absolute",
        left: position.x,
        top: position.y,
        width: size.width + 50, // Add fixed section width
        height: props.height,
        border: "1px solid lightgray",
        background: "transparent",
      }}
    >
      {/* Fixed Section */}
      <div
        style={{
          flex: "0 0 auto",
          width: "50px", // Fixed width for the fixed section
          borderRight: "0 px",
          overflow: "hidden",
          backgroundColor: "lightgray",
        }}
      >
        {props.fixedChildren}
      </div>

      {/* Resizable Section */}
      <div
        style={{
          flex: "1 1 auto",
          width: size.width,
          overflow: "hidden",
        }}
      >
        {props.resizableChildren}
      </div>

      {/* Resize Handle */}
      <div
        onMouseDown={onResizeMouse}
        style={{
          cursor: "se-resize",
          position: "absolute",
          right: 0,
          bottom: 0,
          height: "1.5em",
          width: "1.5em",
          backgroundColor: "orange",
        }}
      >
        <AspectRatioIcon sx={{ fontSize: "1.25em" }} />
      </div>

      {/* Move Handle */}
      <div
        onMouseDown={onMoveMouse}
        style={{
          cursor: "move",
          position: "absolute",
          left: 0,
          top: 0,
          height: "1.5em",
          width: "1.5em",
          backgroundColor: "orange",
        }}
      >
        <OpenWithOutlinedIcon sx={{ fontSize: "1.25em" }} />
      </div>
    </div>
  );
};

export default Resizable;
